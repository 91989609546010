<template>
  <div style="">
    <div>
      <el-button
        type="primary"
        :plain="selectId == item.id ? false : true"
        v-for="item of listButton"
        :key="item.id"
        @click="clickButton(item.id)"
      >
        {{ item.label }}
      </el-button>
    </div>
    <div v-html="content" ref="htmlDom"></div>

    <el-empty v-if="empty_show" description="暂无数据"></el-empty>
  </div>
</template>
<script>
export default {
  data() {
    return {
      listButton: [
        {
          id: "4099198209727922176",
          label: "设备场所清单",
        },
        {
          id: "4099197416652144640",
          label: "风险点登记台账",
        },
        {
          id: "4099197809113169920",
          label: "作业活动清单",
        },
        {
          id: "4099200797860954112",
          label: "工作危害分析",
        },
        {
          id: "4099204924523413504",
          label: "作业活动风险分级管控清单",
        },
        {
          id: "4099206307452551168",
          label: "安全检查表分析",
        },
        {
          id: "4099207670295166976",
          label: "设备场所风险分级管控清单",
        },
      ],
      selectId: "4099198209727922176",
      content: "",

      empty_show: false
    };
  },
  created() {
    this.getContent();
  },
  methods: {
    clickButton(id) {
      this.selectId = id;
      this.getContent();
    },
    getContent() {
      this.content = "";
      this.$http.courseware.info({ id: this.selectId }).then((res) => {
        if(res.data) {
          this.content = res.data.content || ''
          console.log(this.content.length, '123')
          this.empty_show = this.content.length == 0
        }
      });
    },
  },
};
</script>
